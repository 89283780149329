html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  padding: 0;
  font-family: sans-serif;
  font-size:14px;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background-color: #f5f5f5;
}
.footer .feedback{
	padding:10px;
	font-size:12px;
}
.panel-title>a{
	display: block;
    cursor: pointer;
}
/*heading*/
.navbar-default {
	background:linear-gradient(to bottom,#f6f6f6 0,#fff 100%);
	border: none;
    border-bottom: thin solid #ddd;
    
}
.navbar-default .navbar-collapse{
	position:relative;
}
.navbar-brand{
	float:none;
	height: auto;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
    display: block;
    margin: 0;
}
.navbar-brand img{
	height:80px;
	float:none;
	margin:10px auto;
}
.univNameBox{
	float:left;
	margin: 10px 10px;
}
.navbar-header h3 {
    font-size: 16px;    
    padding: 0;    
	text-shadow: 2px 2px 3px #ccc;
	color:#2c7676;
	font-weight:bold;
	text-align:center;
	margin-top:0;
}
.navbar-header h4{
	color:#7da4ad;
	font-size:14px;	
    padding: 0;    
    text-shadow: 2px 2px 3px #ccc;
    color: #2c7676;
    font-weight: bold;
	text-align:center;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
	background-color:transparent;
	color: #2c7676;
    text-decoration: underline;
}
/*/heading*/
/*form*/
.form-control{
	margin-bottom:10px;
}
.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
/*/form*/
/*datepicker*/
.react-datepicker {
	font-size:0.8em;
}
.react-datepicker__current-month {
	font-size:1em;
}
.react-datepicker__input-container{
	display:block;
}
/*/datepicker*/
/*content*/
.toolbar{
	padding:10px 14px;
}
#pageDropDown{display:none;}/*buggy*/
.tab-content{
	padding-top:20px;
}
.page-header {
	margin:0 0 10px;
}
.page-header h1{
	font-size:1.4em;
	margin:0;
}
.field-value{
	padding-top:7px;
}
.view-detail .form-group label{
	padding-right:0;
}
.view-detail.form-horizontal .form-group{
	margin-bottom:4px;
}
.notification-wrapper > *{
	line-height:32px;
	margin-bottom:10px;
	display:inline-block;
}
.pagination{
	float:right;
}
.schedule-table{
	
}
.panel-body{
	padding:5px;
}
/*/content*/
/*login*/
#logo{
    max-width: 100px;
    margin: 0 auto;
}
.login-clean {
	padding:0px 0;
	margin-top:50px;
}
.login-clean h2{
	font-size:22px;
}
.login-clean form {
  max-width:320px;
  width:90%;
  margin:0 auto;
  background-color:#f1f7fc;
  padding:40px;
  border-radius:4px;
  color:#505e6c;
  box-shadow:1px 1px 5px rgba(0,0,0,0.1);
}

.login-clean .illustration {
  text-align:center;
  padding:0 0 20px;
  font-size:100px;
  color:rgb(244,71,107);
}

.login-clean form .form-control {
  background:#f7f9fc;
  border:none;
  border-bottom:1px solid #dfe7f1;
  border-radius:0;
  box-shadow:none;
  outline:none;
  color:inherit;
  text-indent:8px;
  height:42px;
}

.login-clean form .btn-primary {  
  border:none;
  border-radius:4px;
  padding:11px;
  box-shadow:none;
  margin-top:26px;
  text-shadow:none;
  outline:none !important;
}

.login-clean form .btn-primary:hover, .login-clean form .btn-primary:active {
  
}

.login-clean form .btn-primary:active {
  transform:translateY(1px);
}

.login-clean form .forgot {
  display:block;
  text-align:center;
  font-size:12px;
  color:#6f7a85;
  opacity:0.9;
  text-decoration:none;
}

.login-clean form .forgot:hover, .login-clean form .forgot:active {
  opacity:1;
  text-decoration:none;
}


/*/login*/
.width-auto{
	width:auto;
}
.row-time{
	
}
.row-time select.form-control{
	width:auto;
}
.float-left{
	float:left;	
}
.float-left span{
	display:block;
	padding:6px 10px;
}
ul.plain{
	margin:0;
	padding:0;
	list-style-type:none;
}
ul.plain li{
	margin:0;
	padding:0;
}
.calendar-box{
	margin-bottom:30px;
}
tr.courseName{
	background-color:#f9f9f9;
}
.verification-dlg .panel-body{
	padding:0;
}
@media (min-width: 768px) /*bootstrap*/ {
	.form-control{
		margin-bottom:0;
	}
	.navbar-right {
		position: absolute;
		right: 20px;
		bottom: 0;
	}
	.navbar-header h3 {
		margin-top:20px;
	}
	.not-login .navbar-header h3{
		margin-top:34px;
	}
	.navbar-brand img{
		height:80px;
		float:left;
		margin:10px 0 10px 20px;
	}
	.navbar-header h4,.navbar-header h3{
		text-align:left;
	}
}
	

